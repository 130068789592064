import React from "react";
import PropTypes from "prop-types";

function BannerText({ title, subTitle }) {
  return (
    <div>
      <div className="md:mb-4 text-5xl text-[#000000] md:leading-[38px] leading-[38px] sm:leading-[64px] font-medium">
        <h2 className=" text-[#0f1921] mb-0 sm:text-[56px] text-3xl md:text-4xl lg:text-5xl lg:leading-1.25 lg:leading-tight 2xl:text-6xl">
          {title}
        </h2>
      </div>
      <div className="sm:text-5xl text-xl 2xl:text-2xl md:text-xl leading-1.4  font-medium sm:leading-2 text-[#4b4219] lg:text-3xl">
        {subTitle}
      </div>
    </div>
  );
}
BannerText.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default BannerText;
