import React from "react";
import StoryCard from "../components/StoryCard";
import PropTypes from "prop-types";

const LookingForInspiration = ({ storyData, title, description, sideBottomImage, sideLeftImage }) => {
  return (
    <>
      <section
        className="relative px-[24px] lg:px-[80px] xl:px-[120px] sm:px-16 md:px-16 2xl:px-[9%] py-12 bg-cover w-full h-[980px] md:h-auto
     bg-[url('../images/looking_for_inspiration/reportmob.webp')] md:bg-[url('../images/looking_for_inspiration/economic.png')] object-cover"
      >
        <div className="mb-10">
          <h3 className="font-bold text-3xl md:text-[1.69rem] w-3/4 mb-4 md:mb-5 lg:text-[1.7rem] xl:text-[2.94rem]">
            {title}
          </h3>
          <p className="lg:text-[0.94rem] xl:text-2xl lg:w-[45%] mb-[20px] pb-[20px]">{description}</p>
        </div>
        <div className="flex flex-col justify-between gap-10 md:gap-0 md:flex-row">
          <StoryCard
            image={storyData[0]["image"]}
            text={storyData[0]["text"]}
            fullStoryLink={storyData[0]["fullStoryLink"]}
          ></StoryCard>
          <StoryCard
            image={storyData[1]["image"]}
            text={storyData[1]["text"]}
            fullStoryLink={storyData[1]["fullStoryLink"]}
          ></StoryCard>
        </div>
      </section>
      <div className="flex justify-between lg:hidden">
        <img src={sideLeftImage} alt="image" />
        <img src={sideBottomImage} alt="image" />
      </div>
    </>
  );
};

LookingForInspiration.propTypes = {
  storyData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      fullStoryLink: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  description: PropTypes.string,
  sideBottomImage: PropTypes.string,
  sideLeftImage: PropTypes.string,
};

export default LookingForInspiration;
