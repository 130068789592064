import React from "react";
import PropTypes from "prop-types";

const MotoEntity = ({ title, featureList }) => {
  return (
    <div className="relative py-4 md:w-1/3">
      <div className="md:block absolute bg-[#ffda00] w-[0.94rem] h-[3.75rem] rounded-full -top-[4.2rem] left-1/2 -translate-x-1/2"></div>
      <h3 className="text-[#ffda00] font-bold text-xl md:text-base mx-auto text-center mb-4 2xl:text-[1.45rem]">
        {title}
      </h3>
      <ul className="space-y-2 md:text-sm text-white list-disc sm:w-[75%] sm:mx-auto">
        {featureList.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

MotoEntity.propTypes = {
  title: PropTypes.string.isRequired,
  featureList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default MotoEntity;
