import React from "react";
import PropTypes from "prop-types";

const ImageFeatureBlock = ({ sideImg, sideBottomImage, heading, subTitle, featureDataList }) => {
  return (
    <>
      <div className="m-auto flex items-center md:flex-row max-w-[85%] pt-12 grid-cols-2 flex-col sm:flex-row md:pb-8">
        <div className="px-4 md:w-1/2">
          <img className="w-full" src={sideImg} alt="sideimage" />
        </div>
        <div className="sm:px-4 sm:w-1/2 pt-12">
          <div className="font-bold text-2xl text-[#000] mb-4 leading-tight 2xl:leading-snug 2xl:text-4xl xl:text-3xl">
            {heading}
          </div>
          <div className="mb-4 text-[#000] leading-normal text-lg xl:text-lg ">
            <p>{subTitle}</p>
          </div>
          <ul className="pl-5 list-disc text-base leading-tight px-1 xl:text-base">
            {featureDataList.map((data) => {
              return (
                <li className="py-1 2xl:text-base text-base" key={data}>
                  {data}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex justify-end">
        <img src={sideBottomImage} alt="image" />
      </div>
    </>
  );
};

ImageFeatureBlock.propTypes = {
  heading: PropTypes.string,
  subTitle: PropTypes.string,
  sideImg: PropTypes.string,
  featureDataList: PropTypes.arrayOf(PropTypes.string),
  sideBottomImage: PropTypes.string,
};

export default ImageFeatureBlock;
