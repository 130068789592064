import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import { TeamBackImg, EverydayInsightFeature, SideBottomImage } from "../../../images/index";
import Button from "../../../components/Button";
import MotoList from "../../../containers/MotoList";
import CheckItOut from "../../../containers/CheckItOut";
import LookingForInspiration from "../../../containers/LookingForInspiration";
import ImageFeatureBlock from "../../../components/ImageFeatureBlock";
import FeatureList from "../../../containers/FeatureList";
import { featureData } from "../../../constants/everday-insights/feature-data-insights";
import { insightMototoLists } from "../../../constants/everday-insights/insight-mototo-lists.js";
import Container from "../../../utils/Container";
import BreadCrumb from "../../../containers/BreadCrumb";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "solutions",
    link: "/solutions",
  },
  {
    text: " everyday insights",
    link: "",
  },
];

const EverydayInsights = ({ data }) => {
  const everydayInsightsBanner = data.everydayInsightsBanner.nodes[0];
  const everydayInsightsFooter = data.everydayInsightsFooter.nodes[0];
  const lookingforInspiration = data.lookingforInspiration.nodes[0];
  return (
    <Layout>
      <Seo title="Solutions-Everyday Insights" />
      <Banner
        bgImg={everydayInsightsBanner.desktopImage.gatsbyImageData}
        mobImg={everydayInsightsBanner.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-12 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16 sm:-mt-6">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold sm:text-normal xl:text-5xl">
                <strong>power</strong>
                <br />
                <strong>everyday insights</strong>
              </h2>
            }
            subTitle={
              <p className="xl:w-full text-[#000000] text-3xl font-medium lg:text-3xl xl:text-5xl sm:text-lg">
                with on-demand
                <br />
                decision support
              </p>
            }
          />
          <Button
            link="https://entomo.co/request-demo/"
            text="get started today"
            style={{ backgroundColor: "#000", color: "#ffda00" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 1rem" }} />
      </Container>
      <ImageFeatureBlock
        sideImg={EverydayInsightFeature}
        sideBottomImage={SideBottomImage}
        heading="power better business decisions across the employment lifecycle"
        subTitle="entomo’s On-demand People Analytics platform is now available providing these to make better decisions – from when people join, to learn, to connect, to perform and to grow:"
        featureDataList={[
          "visualize functional performance indicators, and share across your teams and organization, real-time For the HR function, select from a set of 200+ pre-defined metrics",
          "understand areas of strength and needing attention across the employment lifecycle",
          "translate people analytics to decisions, to action, using contextual behavioral nudges",
        ]}
      />
      <div className="bg-black">
        <MotoList featureList={insightMototoLists} />
      </div>
      <Container>
        <FeatureList featureData={featureData} />
      </Container>
      <CheckItOut
        bgImg={TeamBackImg}
        heading="for small teams working
      on game-changing
      innovations"
        description="explore our pre-configured editions that are designed to help you accomplish targeted objectives."
      />
      <LookingForInspiration
        title="looking for inspiration?"
        description="look no further. See how some of our customers are taking performance management to a whole new level."
        storyData={[
          {
            image: lookingforInspiration.storyOne.gatsbyImageData,
            text: "Transforming productivity and performance for over 60,000 employees for a large government agency – MEA",
            fullStoryLink:
              "https://entomo.co/success-stories/democratized-insights-gamification-for-accelerated-productivity-for-24000-plus-employees/",
          },
          {
            image: lookingforInspiration.storyTwo.gatsbyImageData,
            text: "Transforming employee engagement for over 60 business units for this ‘fastest-growing’ conglomerate",
            fullStoryLink:
              "https://entomo.co/success-stories/transforming-employee-engagement-for-over-60-business-units-for-this-fastest-growing-conglomerate/",
          },
        ]}
      />
      <Banner
        height="auto"
        bgImg={everydayInsightsFooter.desktopImage.gatsbyImageData}
        mobImg={everydayInsightsFooter.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[17%] 2xl:left-28 sm:w-1/2 md:left-12 lg:left-20 left-1.5 md:top-48 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 -mt-20 w-fit mx-8 sm:mx-0 md:-mt-40 xl:-mt-16 lg:top-52 font-extrabold sm:left-9">
          <BannerText
            title={
              <h2 className="mt-4 text-white text-3xl 2xl:text-5xl">
                <strong>start with the basics</strong>
              </h2>
            }
            subTitle={
              <p className="text-white text-lg sm:w-72 font-normal 2xl:text-2xl">
                connect with our team to get an in-depth understanding of all the features.
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="book a demo"
            style={{ backgroundColor: "#FFDA00", color: "#000000" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
    </Layout>
  );
};
EverydayInsights.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default EverydayInsights;
export const query = graphql`
  query {
    everydayInsightsBanner: allContentfulBannerContent(
      filter: { heading: { eq: "Global solutions everyday insights" } }
    ) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    everydayInsightsFooter: allContentfulBannerContent(
      filter: { heading: { eq: "Global solutions everyday insights footer banner" } }
    ) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    lookingforInspiration: allContentfulLookingForInspiration(
      filter: { heading: { eq: "Global everyday insights looking for inspiration" } }
    ) {
      nodes {
        storyOne {
          gatsbyImageData(formats: WEBP)
        }
        storyTwo {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
  }
`;
