import React from "react";
import PropTypes from "prop-types";
import MotoEntity from "../components/MotoEntity";

const MotoList = ({ featureList, className }) => {
  return (
    <section
      className={
        "flex flex-col gap-20 md:gap-10 md:flex-row md:justify-around px-10 md:px-14 py-10 mx-auto " + className
      }
    >
      {featureList.map((feature) => {
        return <MotoEntity key={feature.title} {...feature} />;
      })}
    </section>
  );
};

MotoList.propTypes = {
  featureList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      featureList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default MotoList;
