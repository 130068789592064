import React from "react";
import { BiChevronRight } from "react-icons/bi";
import PropTypes from "prop-types";
import Button from "../components/Button";

function CheckItOut({ bgImg, heading, description }) {
  return (
    <div className="bg-[#0064bf] relative block sm:mt-8">
      <img className="w-full sm:block hidden" src={bgImg} />
      <div className="sm:absolute text-[25px] sm:block sm:text-[35px] top-[27%] sm:top-[4%] left-[9%] sm:w-[30%] md:w-[40%] lg:w-[35%] xl:w-[30%] xl:top-[27%] lg:top-[20%] md:top-[10%] unset py-[40px] md:py-[0px] mx-[auto] w-[90%] flex flex-col justify-center items-center 2xl:left-[7%]">
        <div className="text-2xl lg:text-[28px] sm:text-[16px] text-center sm:text-left font-extrabold leading-[1.2] text-[#ffffff] mb-[20px] 2xl:text-4xl xl:text-4xl md:text-[25px]">
          {heading}
        </div>
        <div className="sm:text-[18px] text-[15px] text-center leading-[1.4] sm:text-left text-[#ffffff] 2xl:text-xl md:text-[14px] lg:text-[16px] xl:text-lg">
          {description}
        </div>
        <Button
          link="https://entomo.co/all-products/features/"
          text="check it out"
          style={{ backgroundColor: "#ffda00", color: "black", marginTop: "20px" }}
          sideImg={<BiChevronRight />}
        />
      </div>
    </div>
  );
}
CheckItOut.propTypes = {
  bgImg: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
};
export default CheckItOut;
