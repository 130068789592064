import {
  InsightFeatureFive,
  InsightFeatureFour,
  InsightFeatureOne,
  InsightFeatureThree,
  InsightFeatureTwo,
} from "../../images";
export const featureData = [
  {
    title: "seamlessly sync different datasets",
    featureList: [
      "pull together data sets from different functions into a single, easy to read unified display..",
      "transform data to meet established integrity benchmarks.",
      "access near-time information from data sources with frequent updates.",
      "cut through the noise of business data – by going from a high-level to granular view.",
    ],
    image: InsightFeatureOne,
  },
  {
    title: "advance your analytics maturity",
    featureList: [
      "create value regardless of where you stand on your analytics maturity curve.",
      "create simple data management frameworks and standard metrics for the organization.",
      "empower managers with automated actionable insights to support decision-making.",
      "use statistical and AI-powered models to predict outcomes and correct gaps.",
    ],
    image: InsightFeatureTwo,
    reversed: "true",
  },
  {
    title: "augment your people analytics capabilities",
    featureList: [
      "from mitigating risks in recruitment pipeline, to assessing skill gaps and nudging learning journeys, to optimizing current resources, aid the best decisions across the employment lifecycle.",
      "get a real-time view on the performance metrics for your team and organization.",
      "help create the best career path for team members.",
    ],
    image: InsightFeatureThree,
  },
  {
    title: "out-of-box visualization, themes and personalization options",
    featureList: [
      "add context to BI visuals with creative themes and personalization options.",
      "create as many or as few visualizations under one single dashboard or multiple dashboards with the same data source.",
    ],
    image: InsightFeatureFour,
    reversed: "true",
  },
  {
    title: "insights-based task creation",
    featureList: [
      "quit manually comparing and analyzing project status. With our BI-enabled system, simply automate efforts.",
      "increase customer confidence and trust by managing and tracking each business activity and timely reports.",
    ],
    image: InsightFeatureFive,
  },
];
