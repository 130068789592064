import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { TiChevronRight } from "react-icons/ti";

export default function StoryCard({ image, text, fullStoryLink }) {
  return (
    <div className="md:max-w-[46%]">
      <GatsbyImage
        image={image}
        alt="news"
        className="looking-for-inspiration-wrapper"
        imgClassName="looking-for-inspiration-img"
      />
      <div className="flex flex-col justify-between relative bg-[#ffda00] h-44 font-bold px-4 lg:px-6 py-5 md:pb-10 rounded-xl mx-auto -mt-12 xl:-mt-9 w-[90%] text-base">
        <p className="mb-3 md:text-[0.82rem] xl:text-[1.13rem]">{text}</p>
        <a className="text-sm md:text-[0.75rem] xl:text-base flex items-center" href={fullStoryLink}>
          get the full story <TiChevronRight />
        </a>
      </div>
    </div>
  );
}

StoryCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  fullStoryLink: PropTypes.string,
};
