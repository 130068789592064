export const insightMototoLists = [
  {
    title: "transparency & visibility",
    featureList: [
      "increase managerial visibility of employees.",
      "start new conversations around collaborative opportunities.",
      "drive performance awareness.",
    ],
  },
  {
    title: "eliminate underachievement",
    featureList: [
      "democratize performance data.",
      "create actionable insights that motivate employees to track and improve performance.",
    ],
  },
  {
    title: "collaboration & co-creation",
    featureList: [
      "get a detailed view to the capacity of every individual in your workforce.",
      "use KPI matrix to visualize how key metrics are shared and supported across teams.",
    ],
  },
];
