import React from "react";
import PropTypes from "prop-types";

import Feature from "../components/Feature";

export default function FeatureList({ featureData }) {
  return (
    <section>
      {featureData.map((item) => (
        <Feature key={item.title} {...item} />
      ))}
    </section>
  );
}

FeatureList.propTypes = {
  featureData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      FeatureList: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.string,
      reversed: PropTypes.bool,
    })
  ),
};
