import React from "react";
import PropTypes from "prop-types";

const Feature = ({ title, featureList, image, reversed = false }) => {
  return (
    <section
      className={
        "flex flex-col gap-9 md:gap-0 items-center justify-between " +
        (reversed ? "md:flex-row-reverse" : "md:flex-row")
      }
    >
      <div className="md:w-[50%] pt-8">
        <h3 className="text-[#0064BF] font-bold text-[1.38rem] md:text-[1.25rem] lg:text-[1.4rem] lg:w-[70%]  mb-6">
          {title}
        </h3>
        <ul className="list-disc text-sm pl-[17px] md:pl-5  md:text-[0.88rem] font-light space-y-3">
          {featureList.map((feature) => (
            <li className="text-[#4D4D4D]  font-medium" key={feature}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <img className="block md:w-[50%] lg:p-4" src={image} alt="feature" />
    </section>
  );
};

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  featureList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  image: PropTypes.string.isRequired,
  reversed: PropTypes.bool,
};

Feature.defaultProps = {
  reversed: false,
};

export default Feature;
